import * as React from "react"
import Layout from "../components/layout"
import { graphql } from "gatsby"
import {Link, useTranslation, useI18next, Trans} from 'gatsby-plugin-react-i18next'
import RocketLogo from "../components/rocket-logo"
import SEO from "../components/seo"

const IndexPage = () => {

  const { t } = useTranslation()
  

  return (
    <Layout noAnimation>
        <SEO title={t('navigation.home')} />
        <div class="grid grid-cols-2 gap-0 place-items-center h-1/2 mt-24 px-5">
              <div className="flex flex-col justify-self-end">
              <div className="bg-pink-700 rounded p-2 font-semibold self-start"> { t('home.msg_one') } </div>
              <div className="font-bold text-2xl"> { t('home.mesg_two') } </div>
              </div>
              <RocketLogo />
            </div>
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
