import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"


const RocketLogo = ({name = "rocket.png", className}) => {
  
    const data = useStaticQuery(graphql`query RocketQuery {
      allImageSharp {
        nodes {
          fluid {
            originalName
          }
          gatsbyImageData(width: 540,quality: 100, placeholder: BLURRED) 
        }
      }
    }
  `)

  const filteredData = data.allImageSharp.nodes.filter( (element, index, array) => element.fluid.originalName === name )
  const { gatsbyImageData } = filteredData.pop()
  const image = getImage(gatsbyImageData)

  return (
    <GatsbyImage className="z-0" image={image} sizes="(max-width: 540px) 540px" alt="logo" />
  )

}

// Image.propTypes = {
//   filename: PropTypes.string.isRequired
// }

export default RocketLogo